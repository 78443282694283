<template>
  <div id="app">
    <h1>接続をテストします</h1>
    <div style="margin:2em 0;">
      接続確認が完了次第、以下のボタンより「<b>テストの結果をダウンロード</b>」が可能となります。<br>
      ダウンロードされた「接続テスト結果」ファイルを、メールにてご送付ください。<br><br>
      ※また、マイク接続確認のため、マイク接続の許可を尋ねられるので、その際は許可をお願い致します。
    </div>
    <button class="button" :disabled="!isComplete" type="button" @click="fileDownload()">テストの結果をダウンロード</button>
    <div style="background:white; padding:2em; border:1px solid black; max-width:60%; margin:auto;">
    <div>
      UserAgent情報
    </div>
    <div>
      {{userAgent}}
    </div>
    <div class='checkContainer'>
      <div :class="['checkUnit',{'success':stateOf('rest_get')==='success'},{'failed':stateOf('rest_get')==='failed'}]">
        <div class="itemName">サーバー接続①</div>
        <div class="dot">・・・</div>
        <div class="state">{{stateOf('rest_get')}}</div>
        <div class="loader" v-if="stateOf('rest_get')==='connecting'"></div>
      </div>
    </div>
    <div class='checkContainer'>
      <div :class="['checkUnit',{'success':stateOf('rest_post')==='success'},{'failed':stateOf('rest_post')==='failed'}]">
        <div class="itemName">サーバー接続②</div>
        <div class="dot">・・・</div>
        <div class="state">{{stateOf('rest_post')}}</div>
        <div class="loader" v-if="stateOf('rest_post')==='connecting'"></div>
      </div>
    </div>
    <div class='checkContainer'>
      <div :class="['checkUnit',{'success':stateOf('rest_delete')==='success'},{'failed':stateOf('rest_delete')==='failed'}]">
        <div class="itemName">サーバー接続③</div>
        <div class="dot">・・・</div>
        <div class="state">{{stateOf('rest_delete')}}</div>
        <div class="loader" v-if="stateOf('rest_delete')==='connecting'"></div>
      </div>
    </div>
    <div class='checkContainer'>
      <div :class="['checkUnit',{'success':stateOf('rest_put')==='success'},{'failed':stateOf('rest_put')==='failed'}]">
        <div class="itemName">サーバー接続④</div>
        <div class="dot">・・・</div>
        <div class="state">{{stateOf('rest_put')}}</div>
        <div class="loader" v-if="stateOf('rest_put')==='connecting'"></div>
      </div>
    </div>
    <div class='checkContainer'>
      <div :class="['checkUnit',{'success':stateOf('weather_get')==='success'},{'failed':stateOf('weather_get')==='failed'}]">
        <div class="itemName">(サーバー接続⑤)</div>
        <div class="dot">・・・</div>
        <div class="state">{{stateOf('weather_get')}}</div>
        <div class="loader" v-if="stateOf('weather_get')==='connecting'"></div>
      </div>
    </div>
    <div class='checkContainer'>
      <div :class="['checkUnit',{'success':stateOf('gcp_rest_get')==='success'},{'failed':stateOf('gcp_rest_get')==='failed'}]">
        <div class="itemName">(サーバー接続⑥)</div>
        <div class="dot">・・・</div>
        <div class="state">{{stateOf('gcp_rest_get')}}</div>
        <div class="loader" v-if="stateOf('gcp_rest_get')==='connecting'"></div>
      </div>
    </div>
    <div class='checkContainer'>
      <div :class="['checkUnit',{'success':stateOf('coordinator_ws')==='success'},{'failed':stateOf('coordinator_ws')==='failed'}]">
        <div class="itemName">websocketサーバー接続</div>
        <div class="dot">・・・</div>
        <div class="state">{{stateOf('coordinator_ws')}}</div>
        <div class="loader" v-if="stateOf('coordinator_ws')==='connecting'"></div>
      </div>
    </div>
    <div class='checkContainer'>
      <div :class="['checkUnit',{'success':stateOf('webrtc_getmedia_mic')==='success'},{'failed':stateOf('webrtc_getmedia_mic')==='failed'}]">
        <div class="itemName">media</div>
        <div class="dot">・・・</div>
        <div class="state">{{stateOf('webrtc_getmedia_mic')}}</div>
        <div class="loader" v-if="stateOf('webrtc_getmedia_mic')==='connecting'"></div>
      </div>
    </div>
    <div class='checkContainer'>
      <div :class="['checkUnit',{'success':stateOf('skyway_peer')==='success'},{'failed':stateOf('skyway_peer')==='failed'}]">
        <div class="itemName">webRTC①</div>
        <div class="dot">・・・</div>
        <div class="state">{{stateOf('skyway_peer')}}</div>
        <div class="loader" v-if="stateOf('skyway_peer')==='connecting'"></div>
      </div>
    </div>
    <div class='checkContainer'>
      <div :class="['checkUnit',{'success':stateOf('skyway_sfu')==='success'},{'failed':stateOf('skyway_sfu')==='failed'}]">
        <div class="itemName">webRTC②</div>
        <div class="dot">・・・</div>
        <div class="state">{{stateOf('skyway_sfu')}}</div>
        <div class="loader" v-if="stateOf('skyway_sfu')==='connecting'"></div>
      </div>
    </div>
    </div>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


  .success{
    color:green;
  }
  .failed{
    color:red;
  }
  .checkContainer{
    display:flex;
    flex-direction:column;
    align-items:center;
  }
  .checkUnit{
    display:flex;
    flex-direction:row;
    justify-content:space-around;
    align-items:center;
    margin:1em;
  }
  .button{
    margin:1em;
  }
  
  
  
  .loader {
  color: #5367BD;
  font-size: 4px;
  width: 1em;
  height: 1em;
  left:22px;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
</style>

<script>
import axios from 'axios'
import Peer from 'skyway-js'
import io from 'socket.io-client'
export default {
  mounted(){

    //ユーザエージェント
    this.userAgent = window.navigator.userAgent.toLowerCase();
    

    //接続処理開始
    this.connectRESTServerGet()
    this.connectRESTServerPost()
    this.connectRESTServerPut()
    this.connectRESTServerDelete()
    this.connectWeatherAPIServerGet()
    this.connectRESTServerGCPGet()
    //skyway
    this.peer.on("error", (err) => {
      this.state.skyway_peer.val = 'failed'
      this.state.skyway_peer.res = err
    })
    this.peer.on("open", () => {
      this.state.skyway_peer.val = 'success'
      const sfuRoom = this.peer.joinRoom("roomName", {
        mode: "sfu",
        stream: this.localStream,
      });
    
      sfuRoom.on("open", () => {
        this.state.skyway_sfu.val = 'success'
      });
    });
    this.getUserMedia()

    //ws
    let socket = io.connect('https://coordinator-dot-cibylink-for-wellworking.df.r.appspot.com', {reconnection: false});
    socket.on('connect', data => {
      this.state.coordinator_ws.val = 'success'
      this.state.coordinator_ws.res = data
    });

    socket.on('connect_error', err => {
      this.state.coordinator_ws.val = 'failed'
      this.state.coordinator_ws.res = err
    });
    socket.on('connect_failed', err => {
      this.state.coordinator_ws.val = 'failed'
      this.state.coordinator_ws.res = err
    });

    //一定時間後にタイムアウトで諦める
    setTimeout(() => {
      this.giveUp()
    },20000)

  },
  data(){
    return{
      peer:new Peer({key:'d7051ee7-0396-4214-a235-efc30df01d5c'}),
      localStream:null,
      socket:undefined,
      state:{
        rest_get:{val:'connecting',res:undefined},
        rest_post:{val:'connecting',res:undefined},
        rest_delete:{val:'connecting',res:undefined},
        rest_put:{val:'connecting',res:undefined},
        weather_get:{val:'connecting',res:undefined},
        gcp_rest_get:{val:'connecting',res:undefined},
        coordinator_ws:{val:'connecting',res:undefined},
        webrtc_getmedia_mic:{val:'connecting',res:undefined},
        skyway_peer:{val:'connecting',res:undefined},
        skyway_sfu:{val:'connecting',res:undefined},
      },
      userAgent:undefined,

    }
  },
  computed:{
    isComplete(){
      let valArr = Object.keys(this.state).map(el => {
        return this.state[el].val 
      })
      if(valArr.includes('connecting')){
        return false
      }
      else {
        return true
      }
    }
  },
  methods:{
    stateOf(str){
      return this.state[str].val
    },
    async getUserMedia(){
      const stream = await navigator.mediaDevices.getUserMedia({video:false,audio:{
        sampleRate:{ideal:48000},
        channelCount:{ideal:2,min:1},
        echoCancellation:true,
        echoCancellationType:'system',
        noiseSuppression:true,
      }}) 
      if(stream != null){
        this.state.webrtc_getmedia_mic.val = 'success'
      }
      else{
        this.state.webrtc_getmedia_mic.val = 'failed'
      }
    },
    async connectWeatherAPIServerGet(){
      axios.get('https://api.openweathermap.org/data/2.5/forecast?q=Tokyo&appid=b85147cff444f33ee64e0cd75ddf11ae&lang=ja&units=metric')
      .then(res => {
        console.log(res)
        this.state.weather_get.val = 'success'
        this.state.weather_get.res = res
        this.state.weather_get.res.data = "dummy"
      })
      .catch(err => {
        console.log(err)
        this.state.weather_get.val = 'failed'
        this.state.weather_get.res = err
      })
    },
    async connectRESTServerGCPGet(){
      axios.get('https://api-dot-cibylink-for-wellworking.df.r.appspot.com/test')
      .then(res => {
        console.log(res)
        this.state.gcp_rest_get.val = 'success'
        this.state.gcp_rest_get.res = res
      })
      .catch(err => {
        console.log(err)
        this.state.gcp_rest_get.val = 'failed'
        this.state.gcp_rest_get.res = err
      })
    },
    async connectRESTServerGet(){
      axios.get('https://api-dot-cibylink-for-wellworking.df.r.appspot.com/test')
      .then(res => {
        console.log(res)
        this.state.rest_get.val = 'success'
        this.state.rest_get.res = res
      })
      .catch(err => {
        console.log(err)
        this.state.rest_get.val = 'failed'
        this.state.rest_get.res = err
      })
    },
    async connectRESTServerPost(){
      axios.post('https://api-dot-cibylink-for-wellworking.df.r.appspot.com/test')
      .then(res => {
        console.log(res)
        this.state.rest_post.val = 'success'
        this.state.rest_post.res = res
      })
      .catch(err => {
        console.log(err)
        this.state.rest_post.val = 'failed'
        this.state.rest_post.res = err
      })
    },
    async connectRESTServerPut(){
      axios.put('https://api-dot-cibylink-for-wellworking.df.r.appspot.com/test')
      .then(res => {
        console.log(res)
        this.state.rest_put.val = 'success'
        this.state.rest_put.res = res
      })
      .catch(err => {
        console.log(err)
        this.state.rest_put.val = 'failed'
        this.state.rest_put.res = err
      })
    },
    async connectRESTServerDelete(){
      axios.delete('https://api-dot-cibylink-for-wellworking.df.r.appspot.com/test')
      .then(res => {
        console.log(res)
        this.state.rest_delete.val = 'success'
        this.state.rest_delete.res = res
      })
      .catch(err => {
        console.log(err)
        this.state.rest_delete.val = 'failed'
        this.state.rest_delete.res = err
      })
    },
    fileDownload () {
      var blob = new Blob([ JSON.stringify({state:this.state,userAgent:this.userAgent}) ], { "type" : 'application/json'} );
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = '接続テスト結果(メールに添付ください).json'
      link.click()   
    },
    giveUp(){
      let remained = Object.keys(this.state).filter(el => {
        return this.state[el].val === 'connecting'
      })
      remained.forEach(el => {
        this.state[el].val = 'failed'
        this.state[el].res = 'Timeout'
      })
    }
  }
}
</script>